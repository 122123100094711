<template>
    <section>
        <div v-if="timeline_data!=null" class="q-px-lg q-pb-md">
            <q-timeline layout="dense" color="primary">
<!--                <div class="project" v-for="e in proj.allProgettos" :key="e.message">-->
<!--                </div>-->
                <div v-for="block in timeline_data.allTimelineBlocks" :key="block.title">
                    <q-timeline-entry heading>{{block.title}}</q-timeline-entry>
                        <q-timeline-entry v-for="element in block.elements" :key="element.title"
                                :title="element.title"
                                :subtitle="element.timePeriod"
                                icon="work"
                        >
                            <div v-html="element.description"></div>
                        </q-timeline-entry>
                </div>
            </q-timeline>
        </div>
    </section>
</template>

<script lang="ts">
    import { request } from "../services/datocms";
    import {getLanguage} from "../services/languageService";

    const timeline_query = `query query_timeline($language: SiteLocale){
            allTimelineBlocks(locale: $language) {
                 title(locale: $language)

                elements {
                    title(locale: $language)
                    timePeriod
                    description(locale: $language, markdown: false)
                }
            }
        }`;


    export default {
        name: "Timeline",
        methods: {
            getLanguage: getLanguage,
        },
        data: () => ({
            timeline_data: null
        }),
        async mounted() {
            console.log();
            try {
                this.timeline_data = await request({
                    query: timeline_query,
                    variables: {
                        language: this.getLanguage()
                    }
                });
                console.log(this.timeline_data.allTimelineBlocks)
                // this.timeline_data.forEach(e => console.log(e));
                // console.log(this.timeline_data[0].title)
            }
            catch (e) {
                console.log(e);
            }
        }
    }
</script>

<style scoped>

</style>
