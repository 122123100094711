<template>
  <div class="max-width center">
    <div class="row">
      <PageTitle title="Su di me"></PageTitle>
    </div>
    <div class="row justify-center">
      <div class="col-lg-4 col-md-3 col-sm-12">
        <PersonalInfo/>
      </div>
      <div class="col-lg-8 col-md-9 col-sm-12">
        <Timeline/>
      </div>

    </div>
  </div>

</template>

<script lang="ts">
    // import HelloWorld from '../components/HelloWorld.vue'

    import PageTitle from "../components/PageTitle.vue";
    import Timeline from "../components/Timeline.vue";
    import PersonalInfo from "../components/PersonalInfo.vue";
    export default {
        name: 'Projects',
        props: {
            msg: String
        },
        components: {
            PersonalInfo,
            Timeline,
            PageTitle
            // HelloWorld
        }
    }
</script>

<style scoped>

</style>
