import { createApp } from 'vue'
import { DatocmsImagePlugin } from "vue-datocms";

import App from './App.vue'
import './registerServiceWorker'
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'


const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(router);
app.use(DatocmsImagePlugin);
app.mount('#app');
