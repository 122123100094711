import { render } from "./App.vue?vue&type=template&id=1fa6d66a&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"

import "./App.vue?vue&type=style&index=0&id=1fa6d66a&scoped=true&lang=css"
import "./App.vue?vue&type=style&index=1&id=1fa6d66a&lang=css"
script.render = render
script.__scopeId = "data-v-1fa6d66a"

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QPageContainer,QToolbar});
