import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import Projects from "../views/Projects.vue";
import Project from "../views/Project.vue";
import About from "../views/About";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/projects/:name",
        name: "Project",
        component: Project,
    },
    {
        path: "/projects",
        name: "Projects",
        component: Projects,
    },
    {
        path: "/about",
        name: "About",
        component: About,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
