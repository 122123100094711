<template>
    <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-12 text-center">
            <h4 class="text-h4">STEFANO LOSCALZO</h4>
            <img height="300" src="../assets/foto_tessera_stefano.jpg" alt="Foto Stefano Loscalzo"/>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-12 text-center my_col">
            <h5 class="text-h5">Informazioni personali</h5>
            <div class="row">
                <div class="col-xs-6 col-sm-6 left">
                    <span class="text-weight-bold">Data di nascita: </span>
                </div>
                <div class="col-xs-6 col-sm-6 right">
                    <span>27/03/1996</span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 col-sm-6 left">
                    <span class="text-weight-bold">Età: </span>
                </div>
                <div class="col-xs-6 col-sm-6 right">
                    <span>25</span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 col-sm-6 left">
                    <span class="text-weight-bold">Luogo di nascita: </span>
                </div>
                <div class="col-xs-6 col-sm-6 right">
                    <span>Chieri(TO)</span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 col-sm-6 left">
                    <span class="text-weight-bold">Cittadinanza: </span>
                </div>
                <div class="col-xs-6 col-sm-6 right">
                    <span>Italiana</span>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 col-sm-6 left">
                    <span class="text-weight-bold">Patente di guida: </span>
                </div>
                <div class="col-xs-6 col-sm-6 right">
                    <span>A2/B Automunito</span>
                </div>
            </div>
            <h5 class="text-h5">Competenze linguistiche</h5>
            <div class="row">
                <div class="col-xs-6 col-sm-6 left">
                    <span class="text-weight-bold">Lingua madre: </span>
                </div>
                <div class="col-xs-6 col-sm-6 right">
                    <span>Italiano</span>
                </div>
            </div> <div class="row">
                <div class="col-xs-6 col-sm-6 left">
                    <span class="text-weight-bold">Lingua inglese: </span>
                </div>
                <div class="col-xs-6 col-sm-6 right">
                    <span>IELTS 6.5, British Council, Livello europeo B2</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PersonalInfo"
    }
</script>

<style scoped>

.left {
  text-align: left;
}
.right {
  text-align: right;
}
.my_col {
  max-width: 400px;
  padding: 30px;
}

</style>
