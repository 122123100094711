import { render } from "./Menu.vue?vue&type=template&id=72f6808a&scoped=true"
import script from "./Menu.vue?vue&type=script&lang=js"
export * from "./Menu.vue?vue&type=script&lang=js"

import "./Menu.vue?vue&type=style&index=0&id=72f6808a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-72f6808a"

export default script
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QToolbarTitle,QDrawer,QList,QItem,QItemSection,QIcon,QItemLabel});
