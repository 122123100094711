<template>
  <q-layout>
      <Menu/>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import Menu from "./components/Menu";

export default {
  name: 'LayoutDefault',

  components: {
    Menu,
  }
}
</script>

<style scoped>
  .q-toolbar {
    height: 100px;
    background-color: rgba(0, 0, 0, 0.7);

  }
</style>
<style>
.max-width {
  max-width: 1400px;
}
.center {
  margin: auto;
}
</style>
