<template>
    <div>
<!--        <Header></Header>-->
        <div class="bg_image"></div>
        <div class="bg_filter"></div>
        <div class="content">
          <div class="inner_content">
            <div class="text">
                <h1 class="text-h1">Ciao, sono Stefano Loscalzo</h1>
                <p class="text-subtitle-1 subtitle">Studente di ingegneria informatica al Politecnico di Torino, appassionato di informatica, tecnologia in generale e di ciclismo.</p>
            </div>
          </div>
        </div>
        </div>

</template>

<style scoped>
.yellow {
  background-color: yellow;
}

.inner_content {
  max-width: 1400px;
  position: relative;
  margin: 0 auto 15vh;
}
    /*.text {*/
    /*  z-index: 10;*/
    /*  !*position: fixed;*!*/
    /*  bottom: 1em;*/
    /*  !*margin-top: 10rem;*!*/
    /*  padding: 5em;*/
    /*}*/
    h1, p {
        color: white;
    }

    .bg_image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1;
        /*todo: alleggerire immagine*/
        background-image: url("../assets/photo.jpg");
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    .bg_filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 3;
      background-color: rgb(0, 0, 0); /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
    }
    .content {
      position: absolute;
      bottom: 0;
      color: white;
      z-index: 10;
      width: 100%;
      text-align: left;
    }

    .text {
      padding-left: 5em;
    }

    .buttons {
        position: absolute;
        top: auto;
        bottom: 10%;
        transform: translateY(-10%);
        margin-top: 3em;
        padding: 5em;
    }
    @media only screen and (max-width: 600px) {
      h1 {
        font-size: 3rem;
        line-height: 3rem;
      }
      .text {
        padding: 3em;
      }
    }

</style>
<script>
    // import Header from "../components/Header";
    export default {
        components: {}
    }
</script>
