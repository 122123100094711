<template>
    <section class="max-width center">
        <PageTitle :title="text[getLanguage()].title" :subtitle="text[getLanguage()].subtitle"></PageTitle>
        <div v-if="proj!=null" class="content">
            <div class="project" v-for="e in proj.allProjects" :key="e.message">
                <Project
                        :name="e.name"
                        :description="e.shortDescription"
                        :languages="e.languages"
                        :image-url="e.image.url"></Project>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
    import Project from '../components/Project'
    import PageTitle from "../components/PageTitle.vue";
    import { request } from "../services/datocms";
    import {getLanguage} from "../services/languageService";

    const projects_query = `query query_progetti($language: SiteLocale){
            allProjects(locale: $language) {
                name
                shortDescription
                languages
                image {
                    url(imgixParams: {})
                }
            }
        }`;


    export default {
        name: 'Projects',
        props: {
            msg: String
        },
        methods: {
            getLanguage: getLanguage,
        },
        data: () => ({
                text: {
                    it: {
                        title: 'I miei progetti',
                        subtitle: 'Un piccolo elenco dei miei progetti personali, scolastici e di altro genere.'
                    },
                    en: {
                        title: 'My projects',
                        subtitle: 'Un piccolo elenco dei miei progetti personali, scolastici e di altro genere.'
                    }
                },
                proj: null,
        }),
        components: {
            PageTitle,
            Project
        },
        async mounted() {
            console.log();
            try {
                this.proj = await request({
                    query: projects_query,
                    variables: {
                        language: this.getLanguage()
                    }
                });
            }
            catch (e) {
                console.log(e);
            }
        }
    };
</script>

<style>
    .project {
        display: inline-block;
    }
    .content {
        text-align: center;
    }
</style>
