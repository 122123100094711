<template>
      <div class="desktop">
        <q-header elevated class="desktop bg-transparent">
        <div class="header--desktop">
          <div class=" max-width center">
            <img class="logo" src="../assets/logo.png" alt="Logo">
            <div class="links">
              <router-link to="/">{{text[getLanguage()].home}}</router-link>
              <router-link to="/about">{{text[getLanguage()].about}}</router-link>
              <router-link to="/projects">{{text[getLanguage()].projects}}</router-link>
            </div>
            <div class="links cv">
              <a class="link" target="_blank" href="./data/cv_stefano_loscalzo.pdf">{{text[getLanguage()].cv}}</a>
            </div>
          </div>

        </div>
        </q-header>

      </div>
      <div class="mobile">
        <q-header elevated class="mobile background-color">

        <q-toolbar class="mobile toolbar">
          <q-btn
              flat
              round
              @click="leftDrawerOpen = !leftDrawerOpen"
              aria-label="Menu"
              overlay
              icon="menu"
          />

          <q-toolbar-title>
            Stefano Loscalzo
          </q-toolbar-title>
          <img class="logo" src="../assets/logo.png" alt="Logo">

<!--          <div>Questo header è solo per prova</div>-->
        </q-toolbar>
          <q-drawer
            v-model="leftDrawerOpen"
            class="bg-white"
        >
          <q-list class="text-black">
            <q-item clickable to="/">
              <q-item-section avatar>
                <q-icon name="home" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{text[getLanguage()].home}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable to="/about">
              <q-item-section avatar>
                <q-icon name="person" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{text[getLanguage()].about}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable to="/projects">
              <q-item-section avatar>
                <q-icon name="work" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{text[getLanguage()].projects}}</q-item-label>
              </q-item-section>
            </q-item>
            <q-item
                clickable
                tag="a"
                target="_blank"
                href="./data/cv_stefano_loscalzo.pdf">
              <q-item-section avatar>
                <q-icon name="download" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{text[getLanguage()].cv}}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>

        </q-drawer>
        </q-header>


      </div>

</template>

<script>
    import {getLanguage} from '../services/languageService'
    import { ref } from 'vue'

    export default {
        name: "Menu",
        methods: {
            getLanguage: getLanguage
        },
        setup () {
          return {
            leftDrawerOpen: ref(false)
          }
        },
        data() {
            return {
                text: {
                    it: {
                        home: 'Home',
                        about: 'Su di me',
                        projects: 'Progetti',
                        cv: 'Scarica CV'
                    },
                    en: {
                        home: 'Home',
                        about: 'About me',
                        projects: 'Projects',
                        cv: 'Download CV'
                    }
                },
                language: 'it'
            }
        },
    }
</script>

<style scoped>
    .header--desktop {
        background-color: rgba(0, 0, 0, 0.7);
        height: 100px;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        /*background-color: white;*/
        z-index: 999;
    }
    .logo {
        float: left;
        margin: 10px;
        height: 80px;
        width: 80px;
        /*display: inline-block;*/
    }
    .cv {
        display: inline-block;
        float: right;
    }
    .links {
        margin-left: 1em;
        margin-top: 50px;
        display: inline-block;
        transform: translateY(-50%);
        /*display: flex;*/
        /*width: 80%;*/
    }
    .background-color {
      background-color: rgba(0, 0, 0, 0.7);
    }
    .mobile {
      visibility: hidden;
    }

    .links a{
        color: white;
        font-size: larger;
        text-transform: uppercase;
        text-decoration: none;
        margin: 1em;
    }

    @media only screen and (max-width: 600px) {
      .desktop {
        visibility: hidden;
      }
      .mobile {
        visibility: visible;
      }
      .logo {
        height: 30px;
        width: 30px;
      }
    }

</style>
