import { render } from "./Timeline.vue?vue&type=template&id=46871966&scoped=true"
import script from "./Timeline.vue?vue&type=script&lang=ts"
export * from "./Timeline.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-46871966"

export default script
import QTimeline from 'quasar/src/components/timeline/QTimeline.js';
import QTimelineEntry from 'quasar/src/components/timeline/QTimelineEntry.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTimeline,QTimelineEntry});
