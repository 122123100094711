<template>
    <section class="max-width center">
<!--        <MenuPlaceholder></MenuPlaceholder>-->
        <PageTitle :title="proj.name"></PageTitle>
<!--&lt;!&ndash;        <h1>{{proj.allProjects[0].name}}</h1>&ndash;&gt;-->
       <div class="description">
         <p v-html="proj.description"></p>
         <span><span class="title_languages">{{text[getLanguage()].languages}}:</span> {{proj.languages}}</span>
         <div class="links">
           <q-btn v-if="proj.demoLink!==''" class="btn" type="a" target="_blank" :href="proj.demoLink" rounded style="background: #f4c40d; color: white" label="DEMO/LINK" />
           <q-btn  v-if="proj.githubLink!==''" class="btn" type="a" target="_blank" :href="proj.githubLink" rounded style="background: #f4c40d; color: white" label="GitHub" />
           <q-btn class="btn" to="/projects" rounded style="background: #f4c40d; color: white" :label="text[getLanguage()].return" />
         </div>
       </div>

      <div class="slideshow">
        <carousel :items-to-show="1" :autoplay="5000">
          <slide v-for="e in proj.gallery" :key="e.title">
            <div  class="carousel__item">
              <div class="slideshow_img">
              <img class="img" :src="e.url" v-bind:alt="e.alt">
            </div>
              <div class="slideshow_title">
                {{ e.title }}
              </div>

            </div>

<!--            <Image :data="e.responsiveImage" />-->
          </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </div>
    </section>
</template>

<script lang="ts">
    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

    // import MenuPlaceholder from "../components/MenuPlaceholder.vue";
    import PageTitle from "../components/PageTitle.vue";
    import { request } from "../services/datocms";
    import {getLanguage} from "../services/languageService";
    import { useRoute } from 'vue-router';
    // import {Project} from '../models/project'

    const project_query = `query query_progetti($language: SiteLocale, $project_name: String){
            allProjects(filter: {name: {eq: $project_name}}, locale: $language) {
                  description(markdown: true)
                  name
                  languages
                  demoLink
                  githubLink
                  gallery {
                      url(imgixParams: {crop: focalpoint, w: "1000", h: "1000"})
                      title
                      alt
                      responsiveImage(
          imgixParams: { fit: crop, w: 300, h: 300, auto: format }
        ) {
                          alt
                          title
                          src
                      }
                  }
            }
        }`;

    export default {
      name: 'Projects',
      props: {
        msg: String
      },
      methods: {
        getLanguage: getLanguage,
      },
      data: () => ({
        text: {
          it: {
            languages: 'Linguaggi e librerie',
            return: 'Ritorna ai progetti'
          },
          en: {
            languages: 'Libraries and languages',
            return: 'Returns to projects'
          }
        },
        proj: {
          name: '',
          description: '',
          languages: '',
          gallery: [],
          githubLink: '',
          demoLink: ''
        }
      }),
      components: {
        PageTitle,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        // MenuPlaceholder,
      },
      async mounted() {
        const route = useRoute();
        const name = route.params.name;
        console.log(name);
        try {
          const res = await request({
            query: project_query,
            variables: {
              project_name: name,
              language: this.getLanguage()
            }
          });
          this.proj = res.allProjects[0];
          console.log('PROJECTS', this.proj);
        } catch (e) {
          console.log(e);
        }
      }
    };
</script>

<style>
    .project {
        display: inline-block;
    }
    .slideshow {
      padding: 30px;
    }
    .slideshow_img {
      display: block;
    }
    .slideshow_title {
      display: block;
    }

    .description {
      padding-left: 2em;
      padding-right: 2em;
    }
    .title_languages {
      font-weight: bold;
    }
    .img {
      max-width: 80vw;
    }
    .links {
      text-align: center;
    }
    .links .btn{
      margin: 30px 5% 10px;
    }
</style>
