<template>
    <div class="title">
        <h2 class="title_h2 text-h2 t">{{title}}</h2>
        <p class="text-subtitle1 subtitle">{{subtitle}}</p>
    </div>

</template>

<script>
    export default {
        name: "PageTitle",
        props: {
            title: String,
            subtitle: String
        }
    }
</script>

<style scoped>
  .title{
    margin: 2em 0 1em 2em;
  }
    .title_h1 {
        margin-top: 0.5em;
        margin-bottom: 0.2em;
    }
    .t{
      padding: 0;
      margin: 0;
    }
    .subtitle {
      padding: 0;
      margin: 0;
    }
</style>
