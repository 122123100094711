<template>
    <section>
        <div v-bind:style="{ 'background-image': 'url(' + getUrl(imageUrl) + ')'}" class="container">
            <div class="content">
                <h1 class="title text-h4">{{name}}</h1>
<!--                            <p><em>Linguaggi:</em> {{languages}}</p>-->
                <p class="text-body2">{{description}}</p>
                <div class="open_btn">
                    <q-btn rounded :to=getLink(name) style="background: #f4c40d; color: white" :label="text[getLanguage()].open" />
                </div>
            </div>

        </div>
    </section>
</template>

<script lang="ts">
    import {getLanguage} from '../services/languageService'
    export default {
        name: 'Project',
        props: {
            name: String,
            description: String,
            languages: String,
            imageUrl: String
        },
        data() {
            return {
                data: null,
                language: 'it',
                text: {
                    it: {
                        open: 'Scopri di più'
                    },
                    en: {
                        open: 'Learn more'
                    }
                }
            }
        },
        methods: {
            getUrl(imgUrl){
              return `${imgUrl}?fit=crop&w=320&h=320&fm=jpg`;
            },
            getLink(name){
              console.log(name);
              return `/projects/${name}`;
            },
            getLanguage: getLanguage,
        },
        components: {
            // HelloWorld
        },
        mounted() {
            console.log(this.imageUrl)
        }
    }
</script>

<style scoped>
    .open_btn {
        text-align: right;
    }
    .title {
        margin-bottom: 0.5em;
    }
    h1 {
        text-align: center;
        margin-top: 0;
        width: 240px;
    }
    p {
        margin-top: 2em;
        width: 240px;
        overflow: hidden;
        text-align: justify;
    }
    .container {
        white-space: normal;
        display: inline-block;
        margin: 10px;
        position: relative;
        width: 320px;
        height: 440px;
        overflow: hidden;
        background-color: yellow;
        border-radius: 10%;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
        background-position: center;
    }
    .content {
        color: white;
        width: 320px;
        overflow: hidden;
        height: 440px;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0, 0.6); /* Black w/opacity/see-through */
        padding: 40px 40px;
    }
</style>
